import { render, staticRenderFns } from "./nursePresAddWt.vue?vue&type=template&id=33757866&scoped=true"
import script from "./nursePresAddWt.vue?vue&type=script&lang=ts"
export * from "./nursePresAddWt.vue?vue&type=script&lang=ts"
import style0 from "./nursePresAddWt.vue?vue&type=style&index=0&id=33757866&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33757866",
  null
  
)

export default component.exports