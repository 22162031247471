
import {
    Watch,
    Mixins,
    Component,
    Vue,
    Ref,
    InjectReactive
} from "vue-property-decorator";
import UploadImg from "@/components/uploadImg.vue";

import {
    cloneDeep
} from "lodash"

import {
    moxaOrderTemplateListApi,
    moxaOrderTemplatePublishApi,
    moxaOrderTemplateAddApi,
    moxaOrderTemplateAcupointOptionApi,
    moxaOrderTemplateMoxaOptionApi,
    moxaPackageTemplateConApi,
    moxaPackageEnableTemplateApi
} from "@/api/moxibustion";
import {
    Form
} from "element-ui";

const initRow = {
    acupointId: "",
    moxibustionId: "",
    time: undefined
}

@Component({
    components: {
        UploadImg
    }
})
export default class NursePresAddPersonal extends Vue {
    @Ref("formRef") formRef!: Form;

    formData = {
        tempName: "",
        img: [],
        rules: {
            tempName: {
                required: true,
                message: "请输入模板名称",
                trigger: ['blur', 'change']
            },
            acupointId: {
                required: true,
                message: '请选择穴位',
                trigger: ['blur', 'change']
            },
            moxibustionId: {
                required: true,
                message: '请选择灸法',
                trigger: ['blur', 'change']
            },
            time: {
                required: true,
                message: '请输入时长',
                trigger: ['blur']
            },
        },
        tableData: [{
            acupointId: "",
            moxibustionId: "",
            time: ""
        }],
    }

    xwOptions = [];
    jfOptions = [];
    sjOptions = [];
    statusOptions = [{
        label: "已启用",
        value: 1
    }, {
        label: "已禁用",
        value: 0
    }]

    mounted() {
        this.getOptions();
    }

    getOptions() {
        moxaOrderTemplateAcupointOptionApi({}).then((res) => {
            this.xwOptions = res.data || [];
            this.xwOptions?.forEach((op) => {
                op.value = op.code;
                op.label = op.name;
            })
        })
    }

    addRow() {
        console.log("add`")
        this.formData.tableData.push(cloneDeep(initRow));
        this.$nextTick(() => {
            const formTableEL = document.querySelector(".formTable");
            const tableWrapper = formTableEL?.querySelector(".el-table__body-wrapper");
            if (tableWrapper) {
                tableWrapper.scrollTop = tableWrapper.scrollHeight;
            }
        })
    }

    delRow(row, index) {
        if (this.formData.tableData?.length <= 1) return;
        this.formData.tableData.splice(index, 1);
    }

    acupointIdChange(row) {
        row.moxibustionId = "";
    }

    moxibustionIdChange(row) {
        this.getMoxaOption(row.acupointId);
    }

    getMoxaOption(acupoint_id) {
        moxaOrderTemplateMoxaOptionApi(acupoint_id).then((res) => {
            let options = res.data || [];
            options?.forEach((op) => {
                op.value = op.code;
                op.label = op.name;
            })
            this.jfOptions = cloneDeep(options);
        }).catch(() => {
            this.jfOptions = [];
        })
    }

    save() {
        this.formRef?.validate().then(() => {
            const params = {
                tempName: this.formData.tempName,
                dtoList: this.formData.tableData || [],
            }

            moxaOrderTemplateAddApi(params).then((res) => {

            })
        })
    }

    cancel() {
        this.formRef?.resetFields();
    }

}
