
import {
    Watch,
    Mixins,
    Component,
    Vue,
    Ref,
    InjectReactive
} from "vue-property-decorator";

@Component({
    components: {
    }
})
export default class NursePresAddXz extends Vue {

    formData = {}

    rules = {}
}

