
import {
    Watch,
    Mixins,
    Component,
    Vue,
    Ref,
    InjectReactive
} from "vue-property-decorator";
import UploadImg from "@/components/uploadImg.vue";
import NursePresAddAj from "./components/nursePresAddAj.vue"
import NursePresAddXz from "./components/nursePresAddXz.vue"
import NursePresAddJm from "./components/nursePresAddJm.vue"
import NursePresAddWt from "./components/nursePresAddWt.vue"
import NursePresAddWy from "./components/nursePresAddWy.vue"

@Component({
    components: {
        UploadImg,
        NursePresAddAj,
        NursePresAddXz,
        NursePresAddJm,
        NursePresAddWt,
        NursePresAddWy
    }
})
export default class StoreFill extends Vue {

    formData = {
        img: [],
        custom: [],
    }

    rules = {}

}
